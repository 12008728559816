import React from "react";
import { Link } from "react-router-dom";

export default function OrderCard({ id, date, total, status }) {
  return (
    <div className="bg-white p-4 shadow-md rounded-lg flex justify-between items-center">
      <div>
        <h3 className="text-xl font-semibold">Pedido #{id}</h3>
        <p className="text-gray-600">Fecha: {date}</p>
        <p className="text-gray-600">Total: {total}</p>
        <p className={`text-${status === "Enviado" ? "yellow" : "green"}-500`}>
          Estado: {status}
        </p>
      </div>
      <Link
        to={`/pedido/${id}`} // Redirigir a la página de detalles del pedido
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Ver Pedido
      </Link>
    </div>
  );
}
