import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa"; // Icono de hamburguesa para el botón flotante

export default function EmployeeSidebar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {/* Botón flotante para abrir el menú en pantallas pequeñas */}
      <button
        className="md:hidden fixed bottom-4 right-4 bg-green-600 text-white p-3 rounded-full shadow-lg z-50"
        onClick={toggleMenu}
      >
        <FaBars size={24} />
      </button>

      {/* Menú desplegable en pantallas pequeñas */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-green-600 text-white z-40 flex flex-col p-4 md:hidden">
          <div className="flex justify-between items-center mb-4">
            {/* Enlace "EMPLEADO" para pantallas pequeñas */}
            <Link to="/empleado" className="text-2xl font-bold" onClick={toggleMenu}>
              EMPLEADO
            </Link>
            <button
              className="text-white text-2xl"
              onClick={toggleMenu}
            >
              ✕ {/* Icono de cerrar */}
            </button>
          </div>
          <nav>
            <ul>
              <li className="p-4 hover:bg-green-500">
                <Link to="/empleado/productos" onClick={toggleMenu}>Productos</Link>
              </li>
              <li className="p-4 hover:bg-green-500">
                <Link to="/empleado/pedidos" onClick={toggleMenu}>Pedidos</Link>
              </li>
              <li className="p-4 hover:bg-green-500">
                <Link to="/empleado/configuracion" onClick={toggleMenu}>Configuración</Link>
              </li>
              <li className="p-4 hover:bg-green-500">
                <Link to="/logout" onClick={toggleMenu}>Cerrar sesión</Link>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/* Sidebar para pantallas grandes */}
      <div className="hidden md:flex min-h-screen w-64 bg-green-600 text-white flex-col">
        <div className="p-4 text-2xl font-bold">
          <Link to="/empleado" className="hover:underline">
            EMPLEADO
          </Link>
        </div>
        <nav className="flex-grow">
          <ul>
            <li className="p-4 hover:bg-green-500">
              <Link to="/empleado/productos">Productos</Link>
            </li>
            <li className="p-4 hover:bg-green-500">
              <Link to="/empleado/pedidos">Pedidos</Link>
            </li>
            <li className="p-4 hover:bg-green-500">
              <Link to="/empleado/configuracion">Configuración</Link>
            </li>
            <li className="p-4 hover:bg-green-500">
              <Link to="/logout">Cerrar sesión</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
