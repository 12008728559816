import React from "react";
import EmployeeSidebar from "../components/empleadoSidebar";
import { Outlet } from "react-router-dom";

export default function EmployeePage() {
  return (
    <div className="flex">
      {/* Sidebar Component */}
      <EmployeeSidebar />

      {/* Content */}
      <div className="flex-grow p-6 bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Bienvenido empleado</h1>

        {/* Dashboard content (shown by default) */}
        <div className="bg-white p-6 shadow-md rounded-lg mb-6">
          <h2 className="text-2xl font-semibold mb-4">Dashboard - Productos y Pedidos</h2>

          {/* Reportes: Productos, Pedidos */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Productos */}
            <div className="bg-green-100 p-4 rounded-md shadow-md">
              <h3 className="text-xl font-semibold">Productos</h3>
              <p>Total Productos: 120</p>
              {/* Example chart or data */}
            </div>

            {/* Pedidos */}
            <div className="bg-yellow-100 p-4 rounded-md shadow-md">
              <h3 className="text-xl font-semibold">Pedidos</h3>
              <p>Total Pedidos: 25</p>
              {/* Example chart or data */}
            </div>
          </div>
        </div>

        {/* Outlet for additional pages */}
        <Outlet />
      </div>
    </div>
  );
}
