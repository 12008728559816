import React, { useState } from "react";
import AdminSidebar from "../components/adminSidebar";

export default function AgregarEmpleado() {
  // Estado para los empleados
  const [empleados, setEmpleados] = useState([
    {
      id: 1,
      nombres: "Pedro",
      apellidos: "Martínez",
      correo: "pedro.martinez@example.com",
      contraseña: "123456",
      fechaNacimiento: "1990-01-15",
      telefono: "555-1234",
      rol: "empleado",
    },
    {
      id: 2,
      nombres: "María",
      apellidos: "García",
      correo: "maria.garcia@example.com",
      contraseña: "abcdef",
      fechaNacimiento: "1985-06-22",
      telefono: "555-5678",
      rol: "empleado",
    },
  ]);

  // Estado para los campos del formulario de nuevo empleado
  const [nuevoEmpleado, setNuevoEmpleado] = useState({
    nombres: "",
    apellidos: "",
    correo: "",
    contraseña: "",
    fechaNacimiento: "",
    telefono: "",
    rol: "empleado", // El rol es "empleado" por defecto
  });

  // Estado para controlar si estamos editando o agregando un nuevo empleado
  const [isEditing, setIsEditing] = useState(false);
  const [editEmpleadoId, setEditEmpleadoId] = useState(null);

  // Maneja los cambios en los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoEmpleado({ ...nuevoEmpleado, [name]: value });
  };

  // Maneja el envío del formulario (agregar o editar)
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      // Editar empleado existente
      setEmpleados(
        empleados.map((empleado) =>
          empleado.id === editEmpleadoId ? { ...empleado, ...nuevoEmpleado } : empleado
        )
      );
      setIsEditing(false);
      setEditEmpleadoId(null);
    } else {
      // Agregar nuevo empleado
      setEmpleados([
        ...empleados,
        { ...nuevoEmpleado, id: empleados.length + 1 },
      ]);
    }
    // Limpiar el formulario
    setNuevoEmpleado({
      nombres: "",
      apellidos: "",
      correo: "",
      contraseña: "",
      fechaNacimiento: "",
      telefono: "",
      rol: "empleado",
    });
  };

  // Maneja la edición de un empleado
  const handleEdit = (id) => {
    const empleado = empleados.find((empleado) => empleado.id === id);
    setNuevoEmpleado(empleado);
    setIsEditing(true);
    setEditEmpleadoId(id);
  };

  // Maneja la eliminación de un empleado
  const handleDelete = (id) => {
    setEmpleados(empleados.filter((empleado) => empleado.id !== id));
  };

  return (
    <div className="flex">
      {/* Sidebar Component */}
      <AdminSidebar />

      {/* Content */}
      <div className="flex-grow p-6 bg-gray-100">
        <h1 className="text-2xl font-bold mb-4">
          {isEditing ? "Editar Empleado" : "Agregar Nuevo Empleado"}
        </h1>

        {/* Formulario compacto para agregar o editar empleado */}
        <form onSubmit={handleSubmit} className="bg-white p-4 rounded-lg shadow-md mb-6 max-w-md">
          <div className="mb-3">
            <label className="block mb-1 font-semibold text-sm">Nombres</label>
            <input
              type="text"
              name="nombres"
              value={nuevoEmpleado.nombres}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded text-sm"
              required
            />
          </div>

          <div className="mb-3">
            <label className="block mb-1 font-semibold text-sm">Apellidos</label>
            <input
              type="text"
              name="apellidos"
              value={nuevoEmpleado.apellidos}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded text-sm"
              required
            />
          </div>

          <div className="mb-3">
            <label className="block mb-1 font-semibold text-sm">Correo Electrónico</label>
            <input
              type="email"
              name="correo"
              value={nuevoEmpleado.correo}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded text-sm"
              required
            />
          </div>

          <div className="mb-3">
            <label className="block mb-1 font-semibold text-sm">Contraseña</label>
            <input
              type="password"
              name="contraseña"
              value={nuevoEmpleado.contraseña}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded text-sm"
              required
            />
          </div>

          <div className="mb-3">
            <label className="block mb-1 font-semibold text-sm">Fecha de Nacimiento</label>
            <input
              type="date"
              name="fechaNacimiento"
              value={nuevoEmpleado.fechaNacimiento}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded text-sm"
              required
            />
          </div>

          <div className="mb-3">
            <label className="block mb-1 font-semibold text-sm">Teléfono</label>
            <input
              type="tel"
              name="telefono"
              value={nuevoEmpleado.telefono}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded text-sm"
              required
            />
          </div>

          <button type="submit" className="bg-blue-500 text-white p-2 rounded text-sm w-full">
            {isEditing ? "Guardar Cambios" : "Agregar Empleado"}
          </button>
        </form>

        {/* Lista de empleados */}
        <h2 className="text-2xl font-bold mb-4">Empleados</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {empleados.map((empleado) => (
            <div key={empleado.id} className="bg-white p-6 shadow-md rounded-lg">
              <h3 className="text-xl font-semibold mb-2">
                {empleado.nombres} {empleado.apellidos}
              </h3>
              <p className="mb-2">
                <strong>Correo:</strong> {empleado.correo}
              </p>
              <p className="mb-2">
                <strong>Teléfono:</strong> {empleado.telefono}
              </p>
              <p className="mb-2">
                <strong>Fecha de Nacimiento:</strong> {empleado.fechaNacimiento}
              </p>
              <p className="mb-2">
                <strong>Rol:</strong> {empleado.rol}
              </p>
              <div className="flex space-x-2 mt-2">
                <button
                  onClick={() => handleEdit(empleado.id)}
                  className="bg-yellow-500 text-white p-2 rounded text-sm"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDelete(empleado.id)}
                  className="bg-red-500 text-white p-2 rounded text-sm"
                >
                  Eliminar
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
