import React from "react";

export default function ClienteCard({ nombres, apellidos, correo, telefono, rol }) {
  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <h2 className="text-xl font-semibold mb-2">
        {nombres} {apellidos}
      </h2>
      <p className="mb-2">
        <strong>Correo:</strong> {correo}
      </p>
      <p className="mb-2">
        <strong>Teléfono:</strong> {telefono}
      </p>
    </div>
  );
}
