// LandingPage.js
import React from "react";
import CustomNavbar from "../components/navbar";
import Slider from "react-slick"; // Importamos el slider de react-slick
import { useNavigate } from "react-router-dom"; // Importamos useNavigate
import ProductCard from "../components/ProductCard"; // Importamos ProductCard

export default function LandingPage() {
  const navigate = useNavigate(); // Hook para navegar entre rutas

  const products = [
    {
      id: 1,
      title: "Producto 1",
      image: "https://via.placeholder.com/150",
      price: "$50",
      rating: 4.5,
    },
    {
      id: 2,
      title: "Producto 2",
      image: "https://via.placeholder.com/150",
      price: "$75",
      rating: 4.7,
    },
    {
      id: 3,
      title: "Producto 3",
      image: "https://via.placeholder.com/150",
      price: "$30",
      rating: 4.1,
    },
  ];

  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleExploreProducts = () => {
    navigate("/store"); // Redirige a la ruta /tienda
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* Navbar */}
      <CustomNavbar />

      {/* Contenido principal */}
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="text-center py-16 bg-white shadow-md">
          <h1 className="text-5xl font-bold text-gray-800 mb-4">
            Bienvenido a MiTienda
          </h1>
          <p className="text-lg text-gray-600 mb-6">
            Explora nuestros productos de alta calidad y disfruta de grandes ofertas.
          </p>
          {/* Botón de Tailwind CSS */}
          <button
            className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            onClick={handleExploreProducts} // Maneja la redirección
          >
            Explorar Productos
          </button>
        </section>

        {/* Nueva sección de descripción de la tienda */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2">
              <h2 className="text-4xl font-bold text-gray-800 mb-4">
                Acerca de MiTienda
              </h2>
              <p className="text-lg text-gray-600">
                En MiTienda, ofrecemos una amplia variedad de productos de la
                mejor calidad. Nuestro compromiso es brindarte la mejor
                experiencia de compra posible, con precios competitivos y
                ofertas exclusivas. Ya sea que busques lo último en moda,
                tecnología o productos para el hogar, ¡tenemos todo lo que
                necesitas!
              </p>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0">
              <img
                src="https://via.placeholder.com/500x300"
                alt="Descripción MiTienda"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>

        {/* Sección de Ofertas Destacadas con Carrusel */}
        <section className="py-16">
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-bold text-gray-800 mb-8">
              Ofertas destacadas
            </h2>

            <Slider {...settings}>
              {products.map((product) => (
                <div className="px-2" key={product.id}>
                  <ProductCard product={product} />
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 py-4 text-center text-white">
        <p>&copy; 2024 MiTienda. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}
