import React, { useState } from "react";

export default function PedidoCard({
  id,
  usuario,
  total,
  estadoInicial,
  fecha,
  metodoPago,
  domicilio,
  guiaInicial,
  onGuiaSubmit,
}) {
  const [estado, setEstado] = useState(estadoInicial);
  const [guia, setGuia] = useState(guiaInicial);
  const [isEditingGuia, setIsEditingGuia] = useState(!guiaInicial);

  // Maneja el cambio de estado del pedido
  const handleEstadoChange = (nuevoEstado) => {
    setEstado(nuevoEstado);
  };

  // Maneja el cambio de la guía
  const handleGuiaChange = (nuevaGuia) => {
    setGuia(nuevaGuia);
  };

  // Función para guardar la guía y notificar a la "base de datos"
  const handleGuiaSubmit = () => {
    onGuiaSubmit(id, guia); // Llama a la función pasada desde el padre
    setIsEditingGuia(false); // Después de guardar, deshabilita el modo de edición
  };

  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <h2 className="text-xl font-semibold mb-2">Pedido #{id}</h2>
      <p className="mb-2">
        <strong>Usuario:</strong> {usuario}
      </p>
      <p className="mb-2">
        <strong>Total:</strong> {total}
      </p>

      {/* Estado editable */}
      <p className="mb-2">
        <strong>Estado:</strong>
        <select
          value={estado}
          onChange={(e) => handleEstadoChange(e.target.value)}
          className="ml-2 p-1 border rounded"
        >
          <option value="Pendiente">Pendiente</option>
          <option value="En camino">En camino</option>
          <option value="Entregado">Entregado</option>
        </select>
      </p>

      <p className="mb-2">
        <strong>Fecha:</strong> {fecha}
      </p>
      <p className="mb-2">
        <strong>Método de Pago:</strong> {metodoPago}
      </p>
      <p className="mb-2">
        <strong>Domicilio:</strong> {domicilio ? "Sí" : "No"}
      </p>

      {/* Solo mostrar la opción de la guía si es a domicilio */}
      {domicilio && (
        <div className="mb-2">
          {guia && !isEditingGuia ? (
            <div>
              <p>
                <strong>Guía:</strong> {guia}
              </p>
              <button
                onClick={() => setIsEditingGuia(true)}
                className="bg-yellow-500 text-white p-2 rounded mt-2"
              >
                Editar Guía
              </button>
            </div>
          ) : (
            <div>
              <input
                type="text"
                value={guia}
                onChange={(e) => handleGuiaChange(e.target.value)}
                placeholder="Agregar guía"
                className="ml-2 p-1 border rounded"
              />
              <button
                onClick={handleGuiaSubmit}
                className="bg-green-500 text-white p-2 rounded mt-2"
              >
                Guardar Guía
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
