import React from "react";
import { useParams, Link } from "react-router-dom";

// Ejemplo de datos detallados de pedidos (puedes reemplazar esto con una llamada a una API)
const orders = [
  {
    id: 1,
    date: "2024-09-28",
    total: "$125",
    status: "Enviado",
    products: [
      { name: "Producto 1", price: "$50", quantity: 1 },
      { name: "Producto 2", price: "$75", quantity: 1 },
    ],
  },
  {
    id: 2,
    date: "2024-09-25",
    total: "$75",
    status: "Entregado",
    products: [
      { name: "Producto 3", price: "$75", quantity: 1 },
    ],
  },
];

export default function OrderDetails() {
  const { id } = useParams(); // Obtener el ID de la URL
  const order = orders.find(order => order.id === parseInt(id)); // Buscar el pedido por ID

  if (!order) {
    return <div>Pedido no encontrado</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="bg-white p-6 shadow-md rounded-lg">
        <h1 className="text-3xl font-bold mb-6">Detalles del Pedido #{order.id}</h1>

        <p className="text-lg mb-2"><strong>Fecha:</strong> {order.date}</p>
        <p className="text-lg mb-2"><strong>Estado:</strong> {order.status}</p>
        <p className="text-lg mb-4"><strong>Total:</strong> {order.total}</p>

        <h2 className="text-2xl font-semibold mb-4">Productos</h2>
        <ul className="list-disc pl-6">
          {order.products.map((product, index) => (
            <li key={index} className="mb-2">
              {product.name} - {product.price} (Cantidad: {product.quantity})
            </li>
          ))}
        </ul>

        <Link
          to="/miCuenta"
          className="mt-6 inline-block px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Volver a mis pedidos
        </Link>
      </div>
    </div>
  );
}
