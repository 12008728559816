// components/ProductCard.js
import React from 'react';
import { Link } from 'react-router-dom';

export default function ProductCard({ product }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center">
      <img
        src={product.image}
        alt={product.title}
        className="w-full h-40 object-cover mb-4 rounded-md"
      />
      <h3 className="text-lg font-semibold text-gray-800 mb-2">{product.title}</h3>
      <p className="text-gray-600">{product.price}</p>
      <p className="text-yellow-500">⭐ {product.rating}</p>

      {/* Botones para agregar al carrito y ver detalles */}
      <div className="mt-4 flex space-x-4">
        <button className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">
          Agregar al carrito
        </button>
        <Link to={`/producto/${product.id}`}>
          <button className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600">
            Ver detalles
          </button>
        </Link>
      </div>
    </div>
  );
}
