import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaStore } from 'react-icons/fa';  // Importa FaStore y FaArrowLeft

export default function CheckoutPage() {
  // Estado para controlar el método de envío
  const [shippingMethod, setShippingMethod] = useState("domicilio"); // 'domicilio' o 'tienda'
  const [paymentMethod, setPaymentMethod] = useState("linea"); // 'linea' o 'tienda' si es entrega en tienda

  // Estado para controlar el progreso de los formularios
  const [showShippingForm, setShowShippingForm] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showBillingForm, setShowBillingForm] = useState(false);

  const handleShippingMethodChange = () => {
    setShippingMethod((prevMethod) =>
      prevMethod === "domicilio" ? "tienda" : "domicilio"
    );
    setShowShippingForm(false); // Reiniciar formularios si se cambia el método
    setShowPaymentForm(false);
    setShowBillingForm(false);
  };

  const handlePaymentMethodChange = () => {
    setPaymentMethod((prevMethod) =>
      prevMethod === "linea" ? "tienda" : "linea"
    );
    setShowShippingForm(false); // Reiniciar formularios al cambiar método de pago
    setShowPaymentForm(false);
    setShowBillingForm(false);
  };

  const handleNext = (formType) => {
    if (formType === "shipping") {
      setShowShippingForm(true);
    } else if (formType === "payment") {
      setShowPaymentForm(true);
    } else if (formType === "billing") {
      setShowBillingForm(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">

{/* Navbar para regresar al carrito y Home */}
<nav className="bg-blue-500 p-4 flex justify-between items-center">
  {/* Botón para regresar al carrito */}
  <Link
    to="/carrito"
    className="text-white flex items-center space-x-2 hover:text-gray-200"
  >
    <FaArrowLeft className="w-4 h-4" />
    <span>Regresar al carrito</span>
  </Link>
  
  {/* Título del checkout */}
  <h1 className="text-white text-xl font-bold">Checkout</h1>
  
  {/* Botón para regresar a la tienda con icono de tienda */}
  <Link to="/store" className="text-white text-2xl font-bold flex items-center">
    <FaStore className="w-6 h-6" />
    <span className="sr-only">Tienda</span> {/* Texto oculto para accesibilidad */}
  </Link>
</nav>

      {/* Resumen de compra */}
      <div className="bg-white p-4 shadow-md rounded-lg mb-4">
        <h2 className="text-2xl font-semibold mb-2">Resumen de Compra</h2>
        <div className="my-4">
          {/* Aquí agregarías los productos del carrito */}
          <p>Producto 1 - $50</p>
          <p>Producto 2 - $75</p>
        </div>
        <p className="text-xl font-semibold mt-4">Total: $125</p>
      </div>

      {/* Selección del método de envío con toggle switch */}
      <div className="bg-white p-4 shadow-md rounded-lg mb-4">
        <h2 className="text-2xl font-semibold mb-4">Método de Envío</h2>

        {/* Toggle switch para el método de envío */}
        <div className="flex items-center mb-4">
          <label className="mr-2 text-lg font-medium">Envío a domicilio</label>
          <div
            className={`relative w-12 h-6 flex items-center bg-gray-300 rounded-full cursor-pointer ${
              shippingMethod === "tienda" ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={handleShippingMethodChange}
          >
            <div
              className={`${
                shippingMethod === "tienda" ? "translate-x-6" : "translate-x-0"
              } inline-block w-6 h-6 transform bg-white rounded-full transition-transform`}
            />
          </div>
          <label className="ml-2 text-lg font-medium">Recoger en tienda</label>
        </div>

        {/* Mostrar el siguiente formulario basado en la selección del método de envío */}
        {shippingMethod === "tienda" ? (
          <div>
            <h3 className="text-xl font-semibold mb-2">Método de Pago</h3>

            {/* Toggle switch para el método de pago */}
            <div className="flex items-center mb-4">
              <label className="mr-2 text-lg font-medium">Pagar en línea</label>
              <div
                className={`relative w-12 h-6 flex items-center bg-gray-300 rounded-full cursor-pointer ${
                  paymentMethod === "tienda" ? "bg-blue-500" : "bg-gray-300"
                }`}
                onClick={handlePaymentMethodChange}
              >
                <div
                  className={`${
                    paymentMethod === "tienda"
                      ? "translate-x-6"
                      : "translate-x-0"
                  } inline-block w-6 h-6 transform bg-white rounded-full transition-transform`}
                />
              </div>
              <label className="ml-2 text-lg font-medium">Pagar en tienda</label>
            </div>

            {/* Mostrar solo el botón "Finalizar Compra" si el método de pago es "Pagar en tienda" */}
            {paymentMethod === "linea" ? (
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={() => handleNext("payment")}
              >
                Continuar con el pago
              </button>
            ) : (
              <button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
                Finalizar Compra
              </button>
            )}
          </div>
        ) : (
          <div>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              onClick={() => handleNext("shipping")}
            >
              Continuar al formulario de envío
            </button>
          </div>
        )}
      </div>

      {/* Mostrar formularios solo si "Pagar en línea" está activo */}
      {paymentMethod === "linea" && (
        <>
          {/* Formulario de envío para "domicilio" */}
          {showShippingForm && shippingMethod === "domicilio" && (
            <div className="bg-white p-4 shadow-md rounded-lg mb-4">
              <h2 className="text-2xl font-semibold mb-4">Formulario de Envío</h2>
              <form className="grid grid-cols-1 gap-4">
                <input
                  type="text"
                  placeholder="Nombre"
                  className="p-2 border rounded-md"
                />
                <input
                  type="text"
                  placeholder="Dirección"
                  className="p-2 border rounded-md"
                />
                <input
                  type="text"
                  placeholder="Teléfono"
                  className="p-2 border rounded-md"
                />
              </form>
              <button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={() => handleNext("payment")}
              >
                Continuar al formulario de pago
              </button>
            </div>
          )}

          {/* Formulario de pago */}
          {showPaymentForm && (
            <div className="bg-white p-4 shadow-md rounded-lg mb-4">
              <h2 className="text-2xl font-semibold mb-4">Formulario de Pago</h2>
              <form className="grid grid-cols-1 gap-4">
                {shippingMethod === "domicilio" && (
                  <input
                    type="text"
                    placeholder="Tarjeta de Débito"
                    className="p-2 border rounded-md"
                  />
                )}
              </form>
              <button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={() => handleNext("billing")}
              >
                Continuar al formulario de facturación
              </button>
            </div>
          )}

          {/* Formulario de facturación */}
          {showBillingForm && (
            <div className="bg-white p-4 shadow-md rounded-lg mb-4">
              <h2 className="text-2xl font-semibold mb-4">Formulario de Facturación</h2>
              <form className="grid grid-cols-1 gap-4">
                <input
                  type="text"
                  placeholder="Nombre"
                  className="p-2 border rounded-md"
                />
                <input
                  type="text"
                  placeholder="Dirección"
                  className="p-2 border rounded-md"
                />
                                <input
                  type="text"
                  placeholder="Teléfono"
                  className="p-2 border rounded-md"
                />
              </form>
              <button className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
                Finalizar Compra
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

