import React, { useState } from "react";
import AdminSidebar from "../components/adminSidebar";
import ClienteCard from "../components/clienteCard"; // Componente para mostrar la tarjeta del cliente

export default function ClientesAdmin() {
  // Datos de ejemplo para los clientes
  const [clientes, setClientes] = useState([
    {
      id: 1,
      nombres: "Juan",
      apellidos: "Pérez",
      correo: "juan.perez@example.com",
      telefono: "555-1234",
      rol: "usuario",
    },
    {
      id: 2,
      nombres: "Ana",
      apellidos: "Gómez",
      correo: "ana.gomez@example.com",
      telefono: "555-5678",
      rol: "usuario",
    },
    {
      id: 3,
      nombres: "Carlos",
      apellidos: "López",
      correo: "carlos.lopez@example.com",
      telefono: "555-9876",
      rol: "usuario",
    },
  ]);

  return (
    <div className="flex">
      {/* Sidebar Component */}
      <AdminSidebar />

      {/* Content */}
      <div className="flex-grow p-6 bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Clientes</h1>

        {/* Grid de Cards de clientes */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {clientes.map((cliente) => (
            <ClienteCard
              key={cliente.id}
              nombres={cliente.nombres}
              apellidos={cliente.apellidos}
              correo={cliente.correo}
              telefono={cliente.telefono}
              rol={cliente.rol}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
