// components/CartItem.js
import React from 'react';
import { FaTrash } from "react-icons/fa"; // Importamos el icono de bote de basura

export default function CartItem({ product, increaseQuantity, decreaseQuantity, removeProduct }) {
  return (
    <div className="bg-white p-4 shadow-md rounded-lg flex justify-between items-center">
      <div className="flex items-center">
        {/* Imagen del producto */}
        <img
          src={product.image}
          alt={product.title}
          className="w-16 h-16 object-cover mr-4"
        />
        <div>
          <h2 className="text-xl font-semibold">{product.title}</h2>
          <p className="text-gray-500">Precio: ${product.price}</p>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        {/* Botones de agregar/quitar productos */}
        <button
          className="px-3 py-1 bg-gray-300 rounded-md hover:bg-gray-400"
          onClick={() => decreaseQuantity(product.id)}
        >
          -
        </button>
        <span className="font-semibold">{product.quantity}</span>
        <button
          className="px-3 py-1 bg-gray-300 rounded-md hover:bg-gray-400"
          onClick={() => increaseQuantity(product.id)}
        >
          +
        </button>
      </div>
      {/* Botón de eliminar con icono de bote de basura */}
      <button
        className="ml-4 text-red-500 hover:text-red-700"
        onClick={() => removeProduct(product.id)}
      >
        <FaTrash className="w-5 h-5" />
      </button>
    </div>
  );
}
