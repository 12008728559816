import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"; // Importa el icono de carrito

export default function CustomNavbar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <div>
          <h1 className="text-2xl font-bold text-gray-800">MiTienda</h1>
        </div>

        {/* Menú de escritorio */}
        <div className="hidden md:flex space-x-4 items-center">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => navigate("/login")}
          >
            Iniciar sesión
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => navigate("/registro")}
          >
            Registrarse
          </button>

          {/* Botón con ícono de carrito
          <button
            className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => navigate("/carrito")}
          >
            <FontAwesomeIcon icon={faShoppingCart} className="w-5 h-5 mr-2" /> 
            Carrito
          </button>
          */}
        </div>

        {/* Botón de menú hamburguesa para móviles */}
        <div className="md:hidden">
          <button
            className="text-gray-800 focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Menú desplegable para móviles */}
      {isOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              className="block w-full px-4 py-2 text-left bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => {
                navigate("/login");
                setIsOpen(false);
              }}
            >
              Iniciar sesión
            </button>
            <button
              className="block w-full px-4 py-2 text-left bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => {
                navigate("/registro");
                setIsOpen(false);
              }}
            >
              Registrarse
            </button>
            {/* Botón con ícono de carrito
            <button
              className="block w-full px-4 py-2 text-left bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => {
                navigate("/carrito");
                setIsOpen(false);
              }}
            >
              <div className="flex items-center">
                <FontAwesomeIcon icon={faShoppingCart} className="w-5 h-5 mr-2" />
                Carrito
              </div>
            </button>
            */}
          </div>
        </div>
      )}
    </nav>
  );
}
