import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaShoppingCart, FaUserCircle } from "react-icons/fa";

export default function NavbarStore({ toggleSidebar, toggleDropdown, isSmallScreen }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar lo que suceda cuando se envíe la búsqueda
    console.log("Buscando:", searchQuery);
  };

  return (
    <header className="bg-blue-500 p-4 flex justify-between items-center relative">
      {/* Botón de menú (categorías) */}
      <button className="text-white text-2xl" onClick={isSmallScreen ? toggleDropdown : toggleSidebar}>
        <FaBars />
      </button>

      {/* Nombre de la tienda (enlace a la ruta principal) */}
      <div className="flex items-center space-x-2">
        <img
          src="https://via.placeholder.com/40"
          alt="Logo"
          className="w-8 h-8 rounded-full"
        />
        <Link to="/" className="text-white text-2xl font-bold">
          MiTienda
        </Link>
      </div>

      {/* Barra de búsqueda */}
      <form onSubmit={handleSearchSubmit} className="hidden sm:flex flex-grow max-w-md mx-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Buscar productos..."
          className="flex-grow p-2 rounded-l-md border-2 border-gray-200 focus:outline-none"
        />
        <button
          type="submit"
          className="bg-gray-200 hover:bg-gray-300 p-2 rounded-r-md text-gray-600 border-2 border-l-0 border-gray-200"
        >
          Buscar
        </button>
      </form>

      {/* Iconos de usuario y carrito */}
      <div className="flex items-center space-x-4">
        <Link to="/carrito">
          <FaShoppingCart className="text-white text-2xl" />
        </Link>
      </div>
    </header>
  );
}
