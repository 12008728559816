import React, { useState } from "react";
import EmployeeSidebar from "../components/empleadoSidebar";
import ProductModal from "../components/modalProducto"; // Importamos el modal

export default function ProductosEmpleado() {
  const [productos, setProductos] = useState([
    {
      id: 1,
      nombre: "Producto 1",
      descripcion: "Descripción del Producto 1",
      precio: 100,
      categoriaId: 2,
      puntuacion: 4.5,
      stock: 20,
      estado: "activo",
    },
    {
      id: 2,
      nombre: "Producto 2",
      descripcion: "Descripción del Producto 2",
      precio: 150,
      categoriaId: 3,
      puntuacion: 3.8,
      stock: 15,
      estado: "inactivo",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newProduct, setNewProduct] = useState({
    nombre: "",
    descripcion: "",
    precio: 0,
    categoriaId: "",
    imagen1: null,
    imagen2: null,
    imagen3: null,
    puntuacion: 0,
    stock: 0,
    estado: "activo",
  });

  const handleInputChange = (e) => {
    setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setNewProduct({ ...newProduct, [e.target.name]: e.target.files[0] });
  };

  const handleAddProduct = () => {
    setProductos([...productos, { ...newProduct, id: productos.length + 1 }]);
    setIsModalOpen(false);
  };

  const handleDeleteProduct = (id) => {
    setProductos(productos.filter((producto) => producto.id !== id));
  };

  const handleEditProduct = (id) => {
    console.log("Editando producto:", id);
  };

  return (
    <div className="flex flex-col md:flex-row">
      {/* Sidebar Component */}
      <EmployeeSidebar />

      {/* Content */}
      <div className="flex-grow p-6 bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Lista de Productos</h1>

        {/* Botón para abrir el modal de agregar productos */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 text-white p-2 rounded mb-4"
        >
          Agregar Producto
        </button>

        {/* Tarjetas de productos */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {productos.map((producto) => (
            <div key={producto.id} className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold">{producto.nombre}</h3>
              <p>{producto.descripcion}</p>
              <p className="text-gray-500">Precio: ${producto.precio}</p>
              <p className="text-gray-500">Categoría: {producto.categoriaId}</p>
              <p className="text-gray-500">Puntuación: {producto.puntuacion}</p>
              <p className="text-gray-500">Stock: {producto.stock}</p>
              <p className="text-gray-500">Estado: {producto.estado}</p>
              <div className="mt-4">
                <button
                  onClick={() => handleEditProduct(producto.id)}
                  className="bg-yellow-500 text-white p-2 rounded mr-2"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDeleteProduct(producto.id)}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Borrar
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Modal para agregar productos */}
        <ProductModal
          isOpen={isModalOpen}
          newProduct={newProduct}
          handleInputChange={handleInputChange}
          handleImageChange={handleImageChange}
          handleAddProduct={handleAddProduct}
          closeModal={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
}
