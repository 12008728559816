import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa"; // Icono para el dropdown del usuario
import OrderCard from "../components/orderCards";

export default function UserPage() {
  const [activeTab, setActiveTab] = useState("orders"); // Estado para controlar la pestaña activa
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordUpdated, setPasswordUpdated] = useState(false); // Estado para confirmar si la contraseña fue cambiada
  const [dropdownOpen, setDropdownOpen] = useState(false); // Estado para el dropdown

  // Ejemplo de datos de pedidos
  const orders = [
    { id: 1, date: "2024-09-28", total: "$125", status: "Enviado" },
    { id: 2, date: "2024-09-25", total: "$75", status: "Entregado" },
  ];

  // Manejar cierre de sesión
  const handleLogout = () => {
    console.log("Sesión cerrada");
    // Aquí puedes agregar la lógica para cerrar sesión, como limpiar tokens o redirigir
  };

  // Manejar cambio de contraseña
  const handlePasswordChange = () => {
    if (password === confirmPassword) {
      setPasswordUpdated(true);
    } else {
      alert("Las contraseñas no coinciden");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navbar */}
      <nav className="bg-blue-500 p-4 flex justify-between items-center">
        <h1 className="text-white text-xl font-bold">Mi Perfil</h1>
        {/* Dropdown para opciones del usuario */}
        <div className="relative">
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className="text-white focus:outline-none flex items-center space-x-2"
          >
            <FaUserCircle className="w-8 h-8" />
            <span className="text-white">Usuario</span>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-10">
              <button
                onClick={handleLogout}
                className="block w-full px-4 py-2 text-left text-gray-800 hover:bg-gray-100"
              >
                Cerrar Sesión
              </button>
            </div>
          )}
        </div>
      </nav>

      <div className="p-4">
        {/* Pestañas para cambiar entre vistas */}
        <div className="border-b border-gray-200 mb-6">
          <ul className="flex">
            <li
              className={`mr-2 ${
                activeTab === "orders"
                  ? "border-b-2 border-blue-500"
                  : "text-gray-500"
              }`}
            >
              <button
                className={`px-4 py-2 focus:outline-none ${
                  activeTab === "orders" ? "text-blue-500" : "text-gray-500"
                }`}
                onClick={() => setActiveTab("orders")}
              >
                Ver Pedidos
              </button>
            </li>
            <li
              className={`mr-2 ${
                activeTab === "changePassword"
                  ? "border-b-2 border-blue-500"
                  : "text-gray-500"
              }`}
            >
              <button
                className={`px-4 py-2 focus:outline-none ${
                  activeTab === "changePassword"
                    ? "text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("changePassword")}
              >
                Cambiar Contraseña
              </button>
            </li>
          </ul>
        </div>

        {/* Renderizar vista de pedidos */}
        {activeTab === "orders" && (
          <div className="grid grid-cols-1 gap-6">
            <h2 className="text-2xl font-semibold mb-4">Mis Pedidos</h2>

            {/* Renderizar cada pedido usando OrderCard */}
            {orders.map((order) => (
              <OrderCard
                key={order.id}
                id={order.id}
                date={order.date}
                total={order.total}
                status={order.status}
              />
            ))}
          </div>
        )}

        {/* Renderizar vista de cambio de contraseña */}
        {activeTab === "changePassword" && (
          <div className="flex justify-center mt-6"> {/* Centrar el formulario */}
            <div className="bg-white p-6 shadow-md rounded-lg max-w-md w-full">
              <h2 className="text-2xl font-semibold mb-4 text-center">
                Cambiar Contraseña
              </h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePasswordChange();
                }}
                className="grid grid-cols-1 gap-4"
              >
                <input
                  type="password"
                  placeholder="Nueva contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="p-2 border rounded-md"
                  required
                />
                <input
                  type="password"
                  placeholder="Confirmar nueva contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="p-2 border rounded-md"
                  required
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Actualizar Contraseña
                </button>
              </form>
              {passwordUpdated && (
                <p className="text-green-500 mt-4 text-center">
                  ¡Contraseña actualizada con éxito!
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
