import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { useState, useEffect } from "react";
import RegisterPage from "./clientView/register";
import LandingPage from "./clientView/landing";
import Error404 from "./misc/error404page";
import BlockedPage from "./misc/bloqueo";
import LoginPage from "./clientView/login";
import StorePage from "./clientView/store";
import ProductDetail from "./clientView/productView";
import CartPage from './clientView/cart';
import CheckoutPage from './clientView/checkout';
import UserPage from './clientView/principal';
import OrderDetailsPage from './clientView/orderDetails';
import AdminPage from './adminView/admin';
import ProductosAdmin from './adminView/productosAdmin';
import EmployeePage from './empleadoView/empleado';
import PedidosEmpleado from './empleadoView/pedidosEmpleado';
import ProductosEmpleado from './empleadoView/productosEmpleado';
import ConfiguracionEmpleado from './empleadoView/configuracionEmpleado';
import PedidosAdmin from './adminView/pedidosAdmin';
import ClientesAdmin from './adminView/clientesAdmin';
import ProveedoresAdmin from './adminView/proveedores';
import AgregarEmpleado from './adminView/agregarEmpleado';
import ConfiguracionAdmin from './adminView/configuracionAdmin';

function App() {
  // Simulación del estado de bloqueo recibido desde el backend
  const [blocked, setBlocked] = useState(false);

  // Simulación de recepción del estado de bloqueo (esto podría ser una llamada a la API en una app real)
  useEffect(() => {
    // Aquí podrías hacer una llamada al backend para verificar si el usuario está bloqueado
    const fetchBlockedState = async () => {
      // Simulación: El usuario está bloqueado
      const isBlocked = false; // Esto sería la respuesta del backend
      setBlocked(isBlocked);
    };

    fetchBlockedState();
  }, []);

  // Si la cuenta está bloqueada, redirigir a la página de bloqueo
  if (blocked) {
    return <BlockedPage />;
  }

  return (
    <NextUIProvider>
      <BrowserRouter>
        {/* Config pages
        <BrowserRouter basename="/tienda">
        "homepage": "https://luischay.github.io/tienda",
        */}
        <Routes>
  {/* Ruta para la página principal */}
  <Route path="/" element={<LandingPage />} />
  <Route path="/registro" element={<RegisterPage />} />
  <Route path="/login" element={<LoginPage />} />
  <Route path="/store" element={<StorePage />} />
  <Route path="/producto/:id" element={<ProductDetail />} />
  <Route path="/carrito" element={<CartPage />} />
  <Route path="/checkout" element={<CheckoutPage />} />
  <Route path="/micuenta" element={<UserPage />} />
  <Route path="/pedido/:id" element={<OrderDetailsPage />} />
  <Route path="/admin" element={<AdminPage />} />
  <Route path="/admin/productos" element={<ProductosAdmin />} />
  <Route path="/empleado" element={<EmployeePage />} />
  <Route path="/empleado/pedidos" element={<PedidosEmpleado />} />
  <Route path="/empleado/productos" element={<ProductosEmpleado />} />
  <Route path="/empleado/configuracion" element={<ConfiguracionEmpleado />} />
  <Route path="/admin/pedidos" element={<PedidosAdmin />} />
  <Route path="/admin/clientes" element={<ClientesAdmin />} />
  <Route path="/admin/proveedores" element={<ProveedoresAdmin />} />
  <Route path="/admin/empleados" element={<AgregarEmpleado />} />
  <Route path="/admin/configuracion" element={<ConfiguracionAdmin />} />
  

  
  {/* Otras rutas que puedas tener aquí */}

  {/* Ruta explícita para el 404 */}
  <Route path="/404" element={<Error404 />} />

  {/* Redirigir todas las rutas no encontradas a /404 */}
  <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </NextUIProvider>
  );
}

export default App;
