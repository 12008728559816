import React from "react";

export default function BlockedPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-red-100">
      <h1 className="text-4xl font-bold text-red-600 mb-4">¡PAGINA BLOQUEADA!</h1>
      <p className="text-lg text-red-500 mb-6">
        Esta página fue bloqueada por falta de pago al proveedor. Por favor, realiza el pago para desbloquear tu cuenta.
      </p>
    </div>
  );
}
