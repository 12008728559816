// pages/CartPage.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Importamos useNavigate
import { FaArrowLeft, FaStore } from 'react-icons/fa';  // Importa FaStore y FaArrowLeft
import CartItem from "../components/cartItem"; // Importamos el componente CartItem

export default function CartPage() {
  const navigate = useNavigate(); // Hook para la navegación

  // Ejemplo de productos en el carrito con imágenes
  const initialCart = [
    {
      id: 1,
      title: "Producto 1",
      price: 50,
      quantity: 1,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 2,
      title: "Producto 2",
      price: 75,
      quantity: 2,
      image: "https://via.placeholder.com/100",
    },
    {
      id: 3,
      title: "Producto 3",
      price: 30,
      quantity: 1,
      image: "https://via.placeholder.com/100",
    },
  ];

  const [cart, setCart] = useState(initialCart);

  // Función para aumentar la cantidad de un producto
  const increaseQuantity = (productId) => {
    setCart(
      cart.map((product) =>
        product.id === productId
          ? { ...product, quantity: product.quantity + 1 }
          : product
      )
    );
  };

  // Función para disminuir la cantidad de un producto
  const decreaseQuantity = (productId) => {
    setCart(
      cart.map((product) =>
        product.id === productId && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  };

  // Función para eliminar un producto del carrito
  const removeProduct = (productId) => {
    setCart(cart.filter((product) => product.id !== productId));
  };

  // Calcular el subtotal
  const calculateSubtotal = () => {
    return cart.reduce((acc, product) => acc + product.price * product.quantity, 0);
  };

  // Función para redirigir al checkout
  const handleCheckout = () => {
    navigate("/checkout");
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">

{/* Navbar para regresar a la tienda y Home */}
<nav className="bg-blue-500 p-4 flex justify-between items-center">
  {/* Botón para regresar a la tienda */}
  <Link
    to="/store"
    className="text-white flex items-center space-x-2 hover:text-gray-200"
  >
    <FaArrowLeft className="w-4 h-4" />
    <span>Regresar a la tienda</span>
  </Link>
  
  {/* Título del carrito */}
  <h1 className="text-white text-xl font-bold">Carrito de Compras</h1>

</nav>

      {/* Lista de productos */}
      <div className="grid grid-cols-1 gap-4 my-6">
        {cart.length > 0 ? (
          cart.map((product) => (
            <CartItem
              key={product.id}
              product={product}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              removeProduct={removeProduct}
            />
          ))
        ) : (
          <p className="text-center text-gray-500">El carrito está vacío.</p>
        )}
      </div>

      {/* Subtotal */}
      <div className="text-right mb-4">
        <h2 className="text-xl font-semibold">
          Subtotal: ${calculateSubtotal().toFixed(2)}
        </h2>
      </div>

      {/* Botón de continuar con el pago */}
      <div className="text-right">
        <button
          className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          onClick={handleCheckout} // Redirige a la página de checkout
        >
          Continuar con el pago
        </button>
      </div>
    </div>
  );
}
