import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const [formData, setFormData] = useState({
    correo: '',
    password: '',
  });

  const navigate = useNavigate(); // Hook para la navegación

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    // Aquí harías una llamada al backend para autenticar al usuario
    // Ejemplo de redirección después de inicio de sesión exitoso
    navigate('/dashboard'); // Redirigir al dashboard o página principal tras login exitoso
  };

  return (
    
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 relative">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Iniciar Sesión</h2>
        
        <form onSubmit={handleSubmit}>
          {/* Correo Electrónico */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="correo">
              Correo electrónico
            </label>
            <input
              type="email"
              name="correo"
              id="correo"
              value={formData.correo}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Ingresa tu correo electrónico"
              required
            />
          </div>

          {/* Password */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Contraseña
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Ingresa tu contraseña"
              required
            />
          </div>

          {/* Botón de Iniciar Sesión */}
          <div className="mb-4">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            >
              Iniciar Sesión
            </button>
          </div>
          
          {/* Botón de Volver al inicio */}
          <div className="mb-4">
            <button
              type="button"
              className="w-full bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
              onClick={() => navigate('/')}
            >
              {/* Flecha hacia atrás */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              Volver al inicio
            </button>
          </div>
        </form>

        {/* Opción para Registrarse */}
        <div className="text-center">
          <p className="text-sm text-gray-600">
            ¿No tienes una cuenta?{' '}
            <button
              className="text-blue-500 hover:underline"
              onClick={() => navigate('/registro')}
            >
              Regístrate
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}
