// pages/StorePage.js
import React, { useState, useEffect } from "react";
import ProductCard from "../components/ProductCard";
import NavbarStore from "../components/navbarStore"; // Importamos la barra superior

export default function StorePage() {
  const [showSidebar, setShowSidebar] = useState(false); // Controla la visibilidad del menú lateral en pantallas grandes
  const [showDropdown, setShowDropdown] = useState(false); // Controla el menú dropdown en pantallas pequeñas
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Controla si la pantalla es pequeña

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Definimos como "pantalla pequeña" aquellas menores o iguales a 768px
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar); // Cambia el estado para mostrar u ocultar la barra lateral
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Cambia el estado para mostrar u ocultar el dropdown en pantallas pequeñas
  };

  const categories = [
    "Electrónicos",
    "Computadoras y Accesorios",
    "Celulares y Accesorios",
    "Cuidado Personal y Belleza",
    "Herramientas del Hogar",
    "Carros y Motos",
    "Salud",
    "Gaming",
  ];

  const products = [
    {
      id: 1,
      title: "Producto 1",
      image: "https://via.placeholder.com/150",
      price: "$50",
      rating: 4.5,
    },
    {
      id: 2,
      title: "Producto 2",
      image: "https://via.placeholder.com/150",
      price: "$75",
      rating: 4.7,
    },
    {
      id: 3,
      title: "Producto 3",
      image: "https://via.placeholder.com/150",
      price: "$30",
      rating: 4.1,
    },
    {
      id: 4,
      title: "Producto 4",
      image: "https://via.placeholder.com/150",
      price: "$80",
      rating: 4.9,
    },
    {
      id: 5,
      title: "Producto 5",
      image: "https://via.placeholder.com/150",
      price: "$60",
      rating: 4.4,
    },
    {
      id: 6,
      title: "Producto 6",
      image: "https://via.placeholder.com/150",
      price: "$45",
      rating: 4.0,
    },
    {
      id: 7,
      title: "Producto 7",
      image: "https://via.placeholder.com/150",
      price: "$95",
      rating: 4.8,
    },
    {
      id: 8,
      title: "Producto 8",
      image: "https://via.placeholder.com/150",
      price: "$120",
      rating: 5.0,
    },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Navbar */}
      <NavbarStore
        toggleSidebar={toggleSidebar}
        toggleDropdown={toggleDropdown}
        isSmallScreen={isSmallScreen}
      />

      <div className="flex-grow flex">
        {/* Sidebar para pantallas grandes, mostrar u ocultar con el botón */}
        {!isSmallScreen && showSidebar && (
          <div className={`bg-white w-64 shadow-md p-4 mr-2`}>
            <h2 className="text-xl font-semibold mb-4">Categorías</h2>
            <ul>
              {categories.map((category, index) => (
                <li key={index} className="mb-2 p-2 hover:bg-gray-100 cursor-pointer">
                  {category}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Productos */}
        <div className={`flex-grow p-4 ${showSidebar ? 'ml-64' : ''}`}>
          <h2 className="text-2xl font-semibold mb-4">Productos</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>

      {/* Dropdown para pantallas pequeñas */}
      {isSmallScreen && showDropdown && (
        <div className="bg-white shadow-lg p-4 absolute top-16 w-full">
          <ul>
            {categories.map((category, index) => (
              <li key={index} className="mb-2 p-2 hover:bg-gray-100 cursor-pointer">
                {category}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Footer */}
      <footer className="bg-gray-800 py-4 text-center text-white mt-auto">
        <p>&copy; 2024 MiTienda. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}
