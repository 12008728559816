// pages/ProductDetail.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Para obtener el ID del producto y redirigir

export default function ProductDetail() {
  const { id } = useParams(); // Obtiene el ID del producto desde la URL
  const navigate = useNavigate(); // Para redirigir al usuario

  // Datos de ejemplo de productos
  const products = [
    {
      id: 1,
      title: "Producto 1",
      image: "https://via.placeholder.com/300",
      price: "$50",
      rating: 4.5,
      description: "Este es un excelente producto 1 de alta calidad.",
    },
    {
      id: 2,
      title: "Producto 2",
      image: "https://via.placeholder.com/300",
      price: "$75",
      rating: 4.7,
      description: "Este es un excelente producto 2 con muchas características.",
    },
    {
      id: 3,
      title: "Producto 3",
      image: "https://via.placeholder.com/300",
      price: "$30",
      rating: 4.1,
      description: "Producto 3 económico pero muy útil.",
    },
  ];

  // Encuentra el producto correspondiente al ID
  const product = products.find((product) => product.id === parseInt(id));

  // Redirigir si no se encuentra el producto
  useEffect(() => {
    if (!product) {
      navigate("/404"); // Redirige a la página de error
    }
  }, [product, navigate]);

  if (!product) {
    return null; // Evita renderizar nada mientras redirige
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center p-4">
      <div className="max-w-3xl w-full bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col md:flex-row">
          {/* Imagen del producto */}
          <img
            src={product.image}
            alt={product.title}
            className="w-full md:w-1/2 h-64 object-cover rounded-lg mb-4 md:mb-0"
          />

          {/* Detalles del producto */}
          <div className="md:ml-6 flex flex-col justify-between">
            <div>
              <h2 className="text-3xl font-bold text-gray-800 mb-2">{product.title}</h2>
              <p className="text-gray-600 mb-4">{product.price}</p>
              <p className="text-yellow-500 mb-2">⭐ {product.rating}</p>
              <p className="text-gray-700 mb-6">{product.description}</p>
            </div>

            {/* Botón agregar al carrito */}
            <button className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600">
              Agregar al carrito
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
