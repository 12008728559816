import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export default function Error404() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/"); // Redirige a la página principal
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      {/* Texto 404 grande */}
      <h1 className="text-8xl font-bold text-blue-500 mb-4">404</h1>
      
      {/* Mensaje de error */}
      <h2 className="text-4xl font-semibold text-gray-800 mb-2">¡Oops! Página no encontrada</h2>
      
      {/* Descripción */}
      <p className="text-lg text-gray-600 mb-6">
        Lo sentimos, la página que estás buscando no existe. Puedes volver a la página de inicio.
      </p>

      {/* Botón para regresar a la página principal */}
      <Button shadow color="gradient" auto onClick={handleGoBack}>
        Volver al inicio
      </Button>
    </div>
  );
}
