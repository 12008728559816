import React, { useState } from "react";
import EmployeeSidebar from "../components/empleadoSidebar";
import PedidoCardAdmin from "../components/pedidoCard"; // Componente para mostrar la tarjeta del pedido

export default function PedidosAdmin() {
  // Datos de ejemplo para los pedidos
  const [pedidos, setPedidos] = useState([
    {
      id: 1,
      usuario: "Juan Pérez",
      total: "$150.00",
      estado: "Pendiente",
      fecha: "2024-10-03",
      metodoPago: "Tarjeta de Crédito",
      domicilio: true,
      guia: "",
    },
    {
      id: 2,
      usuario: "Ana Gómez",
      total: "$230.50",
      estado: "En camino",
      fecha: "2024-10-01",
      metodoPago: "PayPal",
      domicilio: false,
      guia: "",
    },
    {
      id: 3,
      usuario: "Carlos López",
      total: "$89.99",
      estado: "Entregado",
      fecha: "2024-09-29",
      metodoPago: "Tarjeta de Crédito",
      domicilio: true,
      guia: "456-789-123",
    },
  ]);

  // Función para manejar el guardado de la guía y el estado
  const handleGuiaSubmit = (id, nuevaGuia) => {
    const pedidosActualizados = pedidos.map((pedido) =>
      pedido.id === id ? { ...pedido, guia: nuevaGuia } : pedido
    );
    setPedidos(pedidosActualizados);
    console.log(`Guía enviada a la base de datos para el pedido ${id}: ${nuevaGuia}`);
  };

  const handleEstadoChange = (id, nuevoEstado) => {
    const pedidosActualizados = pedidos.map((pedido) =>
      pedido.id === id ? { ...pedido, estado: nuevoEstado } : pedido
    );
    setPedidos(pedidosActualizados);
    console.log(`Estado actualizado para el pedido ${id}: ${nuevoEstado}`);
  };

  return (
    <div className="flex">
      {/* Sidebar Component */}
      <EmployeeSidebar />

      {/* Content */}
      <div className="flex-grow p-6 bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Pedidos Actuales</h1>

        {/* Grid de Cards de pedidos */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {pedidos.map((pedido) => (
            <PedidoCardAdmin
              key={pedido.id}
              id={pedido.id}
              usuario={pedido.usuario}
              total={pedido.total}
              estadoInicial={pedido.estado}
              fecha={pedido.fecha}
              metodoPago={pedido.metodoPago}
              domicilio={pedido.domicilio}
              guiaInicial={pedido.guia}
              onGuiaSubmit={handleGuiaSubmit}
              onEstadoChange={handleEstadoChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
