import React from "react";

export default function ProductModal({
  isOpen,
  newProduct,
  handleInputChange,
  handleImageChange,
  handleAddProduct,
  closeModal,
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md max-h-screen overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Agregar Producto</h2>

        <div className="mb-4">
          <label className="block mb-2">Nombre</label>
          <input
            type="text"
            name="nombre"
            value={newProduct.nombre}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Descripción</label>
          <input
            type="text"
            name="descripcion"
            value={newProduct.descripcion}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Precio</label>
          <input
            type="number"
            name="precio"
            value={newProduct.precio}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">ID Categoría</label>
          <input
            type="text"
            name="categoriaId"
            value={newProduct.categoriaId}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Imagen 1</label>
          <input
            type="file"
            name="imagen1"
            onChange={handleImageChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Imagen 2</label>
          <input
            type="file"
            name="imagen2"
            onChange={handleImageChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Imagen 3</label>
          <input
            type="file"
            name="imagen3"
            onChange={handleImageChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Puntuación</label>
          <input
            type="number"
            name="puntuacion"
            value={newProduct.puntuacion}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Stock</label>
          <input
            type="number"
            name="stock"
            value={newProduct.stock}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Estado</label>
          <select
            name="estado"
            value={newProduct.estado}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="activo">Activo</option>
            <option value="inactivo">Inactivo</option>
          </select>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleAddProduct}
            className="bg-green-500 text-white p-2 rounded"
          >
            Guardar Producto
          </button>
          <button
            onClick={closeModal}
            className="bg-gray-500 text-white p-2 rounded ml-2"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}
