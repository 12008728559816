import React, { useState } from "react";
import AdminSidebar from "../components/adminSidebar";

export default function ProveedoresAdmin() {
  // Datos de ejemplo para los proveedores
  const [proveedores, setProveedores] = useState([
    {
      id: 1,
      nombre: "Proveedor 1",
      direccion: "Calle Falsa 123",
      telefono: "555-1234",
      correo: "proveedor1@example.com",
    },
    {
      id: 2,
      nombre: "Proveedor 2",
      direccion: "Avenida Siempreviva 742",
      telefono: "555-5678",
      correo: "proveedor2@example.com",
    },
    {
      id: 3,
      nombre: "Proveedor 3",
      direccion: "Calle 45, No. 10",
      telefono: "555-9876",
      correo: "proveedor3@example.com",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProveedor, setSelectedProveedor] = useState(null);

  // Función para abrir el modal de edición
  const openModal = (proveedor) => {
    setSelectedProveedor(proveedor);
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProveedor(null);
  };

  // Función para guardar los cambios del proveedor
  const handleSaveProveedor = (updatedProveedor) => {
    setProveedores(
      proveedores.map((proveedor) =>
        proveedor.id === updatedProveedor.id ? updatedProveedor : proveedor
      )
    );
    closeModal();
  };

  return (
    <div className="flex">
      {/* Sidebar Component */}
      <AdminSidebar />

      {/* Content */}
      <div className="flex-grow p-6 bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Proveedores</h1>

        {/* Grid de Cards de proveedores */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {proveedores.map((proveedor) => (
            <ProveedorCard
              key={proveedor.id}
              proveedor={proveedor}
              onEdit={() => openModal(proveedor)}
            />
          ))}
        </div>

        {/* Modal para editar proveedores */}
        {isModalOpen && selectedProveedor && (
          <ProveedorModal
            proveedor={selectedProveedor}
            onSave={handleSaveProveedor}
            onClose={closeModal}
          />
        )}
      </div>
    </div>
  );
}

// Componente ProveedorCard para mostrar la información del proveedor en una tarjeta
function ProveedorCard({ proveedor, onEdit }) {
  return (
    <div className="bg-white p-6 shadow-md rounded-lg">
      <h2 className="text-xl font-semibold mb-2">{proveedor.nombre}</h2>
      <p className="mb-2">
        <strong>Dirección:</strong> {proveedor.direccion}
      </p>
      <p className="mb-2">
        <strong>Teléfono:</strong> {proveedor.telefono}
      </p>
      <p className="mb-2">
        <strong>Correo:</strong> {proveedor.correo}
      </p>
      <button
        onClick={onEdit}
        className="bg-yellow-500 text-white p-2 rounded mt-2"
      >
        Editar Proveedor
      </button>
    </div>
  );
}

// Componente ProveedorModal para editar la información del proveedor
function ProveedorModal({ proveedor, onSave, onClose }) {
  const [nombre, setNombre] = useState(proveedor.nombre);
  const [direccion, setDireccion] = useState(proveedor.direccion);
  const [telefono, setTelefono] = useState(proveedor.telefono);
  const [correo, setCorreo] = useState(proveedor.correo);

  const handleSave = () => {
    const updatedProveedor = { ...proveedor, nombre, direccion, telefono, correo };
    onSave(updatedProveedor);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Editar Proveedor</h2>

        <div className="mb-4">
          <label className="block mb-2">Nombre</label>
          <input
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Dirección</label>
          <input
            type="text"
            value={direccion}
            onChange={(e) => setDireccion(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Teléfono</label>
          <input
            type="text"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Correo</label>
          <input
            type="email"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-green-500 text-white p-2 rounded"
          >
            Guardar
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white p-2 rounded ml-2"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}
